.o-wrapper {
    margin: 0 auto;
    max-width: 1920px;
    padding: 0 10px;
    position: relative;
    width: 100%;

    @include screen-min(m) {
        padding: 0 space(xxl);
    }

    &__inner {
        padding: 0 10px;
        
        @include screen-min(m) {
            margin: 0 auto;
            width: 90%;
        }
    }
}