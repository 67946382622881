.c-section {
    color: rgb(var(--color-text));
    padding: space(xxl) 0;
    position: relative;

    @include screen-min(l) {
        padding: space(6xl) 0;
    }

    &--color {
        background: rgb(var(--color));
    }

    &__header {
        font-size: var(--font-size-l);
        margin-bottom: space(xl);

        @include screen-min(l) {
            font-size: var(--font-size-xl);
            margin-bottom: space(3xl);
            width: 50%;
        }
    }
}