/* http://meyerweb.com/eric/tools/css/reset/
   v5.0.1 | 20191019
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, menu, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block;
}

/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
  display: none;
}

body {
  line-height: 1;
}

menu, ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

:root {
  --border-radius: 4px;
  --duration-fast: 150ms;
  --duration-medium: 250ms;
  --duration-slow: 500ms;
  --color: 187 85 68;
  --color-bg: 246 241 238;
  --color-text: 187 85 68;
  --color-button: 255, 255, 255;
  --color-button-hover: 0, 255, 255;
  --color-button-text: 0, 0, 0;
  --color-button-primary: 187 85 68;
  --color-button-primary-hover: 187 85 68;
  --color-button-primary-text: 255 255 255;
  --color-button-primary-text-hover: 255 255 255;
  --color-link: 187 85 68;
  --color-link-hover: 187 85 68;
  --font-1: "DM Sans", sans-serif;
  --font-1-spacing: 0;
  --font-2: "PT Serif", sans-serif;
  --font-2-spacing: 0;
  --font-mono: "DM Mono", monospace;
  --font-mono-spacing: -0.1em;
  --font-size-xxs: 11px;
  --font-size-xs: 12px;
  --font-size-s: 14px;
  --font-size-m: 16px;
  --font-size-l: 18px;
  --font-size-xl: 20px;
  --font-size-xxl: 28px;
  --font-size-3xl: 36px;
  --line-height-s: 1.3;
  --line-height-m: 1.5;
  --line-height-l: 1.75;
}

@media (min-width: 768px) {
  :root {
    --font-size-xxl: 36px;
  }
}
@media (min-width: 1024px) {
  :root {
    --font-size-l: 20px;
    --font-size-xl: 24px;
    --font-size-xxl: 48px;
  }
}
.t-inverted {
  --color-bg: 187 85 68;
  --color-text: 246 241 238;
  --color-link: 246 241 238;
  --color-link-hover: 246 241 238;
  --color-button-primary: 255 255 255;
  --color-button-primary-hover: 255 255 255;
  --color-button-primary-text: 187 85 68;
  --color-button-primary-text-hover: 0 0 0;
}

@keyframes spinner {
  100% {
    transform: rotate(360deg);
  }
}
a {
  color: rgb(var(--color-link));
  text-decoration: none;
}
a:hover {
  color: rgb(var(--color-link-hover));
}

b,
strong {
  font-weight: 500;
}

body {
  font-family: var(--font-1);
  letter-spacing: var(--font-1-spacing);
  background: rgb(var(--color-bg));
  color: rgb(var(--color-text));
  line-height: var(--line-height-m);
  text-rendering: optimizelegibility;
}
body.is-loading, body.is-loading * {
  cursor: wait;
  pointer-events: none;
}

button {
  font-family: var(--font-2);
  letter-spacing: var(--font-2-spacing);
  display: inline-block;
  margin: 0;
  padding: 0;
}

form.is-busy, form.is-busy * {
  cursor: wait;
  pointer-events: none;
}

*,
*::after,
*::before {
  font-family: var(--font-1);
  letter-spacing: var(--font-1-spacing);
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  outline: none;
}

::selection {
  background: rgb(var(--color));
  color: #FFF;
}

h1 {
  font-family: var(--font-1);
  letter-spacing: var(--font-1-spacing);
  font-size: var(--font-size-xxl);
  font-weight: 400;
  line-height: var(--line-height-s);
}

h2 {
  font-family: var(--font-1);
  letter-spacing: var(--font-1-spacing);
  font-size: var(--font-size-xxl);
  line-height: var(--line-height-s);
}
@media (min-width: 1024px) {
  h2 {
    font-size: var(--font-size-3xl);
  }
}

h3 {
  font-family: var(--font-1);
  letter-spacing: var(--font-1-spacing);
  color: rgb(var(--color-1));
  font-size: var(--font-size-l);
  font-weight: 600;
  line-height: var(--line-height-s);
}

h4 {
  font-size: var(--font-size-m);
  font-weight: 700;
  line-height: line-height(s);
}

html {
  font-size: 16px;
  scroll-behavior: smooth;
}

img {
  display: block;
  height: auto;
  max-width: 100%;
}

input {
  font-family: var(--font-1);
  letter-spacing: var(--font-1-spacing);
  margin: 0;
  outline: none;
  padding: 0;
}

input::placeholder {
  color: var(--color-input-placeholder);
  font-family: var(--font-1);
  opacity: 1;
  text-transform: none;
}

input::-ms-input-placeholder {
  color: var(--color-input-placeholder);
  font-family: var(--font-1);
  opacity: 1;
  text-transform: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background 9999s;
  transition-delay: 9999s;
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.o-grid {
  display: flex;
  flex-flow: row wrap;
}
.o-grid__col {
  background-clip: padding-box;
  border: 0px solid transparent;
  flex: 0 0 100%;
}
.o-grid--center {
  justify-content: center;
}
.o-grid--middle {
  align-items: center;
}
.o-grid--evenly {
  justify-content: space-evenly;
}

.o-grid__col--span-1 {
  flex-basis: calc(1 * (100% / 12));
}

.o-grid__col--span-2 {
  flex-basis: calc(2 * (100% / 12));
}

.o-grid__col--span-3 {
  flex-basis: calc(3 * (100% / 12));
}

.o-grid__col--span-4 {
  flex-basis: calc(4 * (100% / 12));
}

.o-grid__col--span-5 {
  flex-basis: calc(5 * (100% / 12));
}

.o-grid__col--span-6 {
  flex-basis: calc(6 * (100% / 12));
}

.o-grid__col--span-7 {
  flex-basis: calc(7 * (100% / 12));
}

.o-grid__col--span-8 {
  flex-basis: calc(8 * (100% / 12));
}

.o-grid__col--span-9 {
  flex-basis: calc(9 * (100% / 12));
}

.o-grid__col--span-10 {
  flex-basis: calc(10 * (100% / 12));
}

.o-grid__col--span-11 {
  flex-basis: calc(11 * (100% / 12));
}

.o-grid__col--span-12 {
  flex-basis: calc(12 * (100% / 12));
}

.o-grid--gap-xxs {
  margin: -0.0625rem;
}
.o-grid--gap-xxs > .o-grid__col {
  border-width: 0.0625rem;
}

.o-grid--gap-xs {
  margin: -0.125rem;
}
.o-grid--gap-xs > .o-grid__col {
  border-width: 0.125rem;
}

.o-grid--gap-s {
  margin: -0.25rem;
}
.o-grid--gap-s > .o-grid__col {
  border-width: 0.25rem;
}

.o-grid--gap-m {
  margin: -0.5rem;
}
.o-grid--gap-m > .o-grid__col {
  border-width: 0.5rem;
}

.o-grid--gap-l {
  margin: -0.75rem;
}
.o-grid--gap-l > .o-grid__col {
  border-width: 0.75rem;
}

.o-grid--gap-xl {
  margin: -1rem;
}
.o-grid--gap-xl > .o-grid__col {
  border-width: 1rem;
}

.o-grid--gap-xxl {
  margin: -1.5rem;
}
.o-grid--gap-xxl > .o-grid__col {
  border-width: 1.5rem;
}

.o-grid--gap-3xl {
  margin: -2rem;
}
.o-grid--gap-3xl > .o-grid__col {
  border-width: 2rem;
}

.o-grid--gap-4xl {
  margin: -2.5rem;
}
.o-grid--gap-4xl > .o-grid__col {
  border-width: 2.5rem;
}

.o-grid--gap-5xl {
  margin: -3rem;
}
.o-grid--gap-5xl > .o-grid__col {
  border-width: 3rem;
}

.o-grid--gap-6xl {
  margin: -3.5rem;
}
.o-grid--gap-6xl > .o-grid__col {
  border-width: 3.5rem;
}

@media (min-width: 320px) {
  .xs\:o-grid--reverse {
    flex-direction: row-reverse;
  }

  .xs\:o-grid__col--span-1 {
    flex-basis: calc(1 * (100% / 12));
  }

  .xs\:o-grid__col--span-2 {
    flex-basis: calc(2 * (100% / 12));
  }

  .xs\:o-grid__col--span-3 {
    flex-basis: calc(3 * (100% / 12));
  }

  .xs\:o-grid__col--span-4 {
    flex-basis: calc(4 * (100% / 12));
  }

  .xs\:o-grid__col--span-5 {
    flex-basis: calc(5 * (100% / 12));
  }

  .xs\:o-grid__col--span-6 {
    flex-basis: calc(6 * (100% / 12));
  }

  .xs\:o-grid__col--span-7 {
    flex-basis: calc(7 * (100% / 12));
  }

  .xs\:o-grid__col--span-8 {
    flex-basis: calc(8 * (100% / 12));
  }

  .xs\:o-grid__col--span-9 {
    flex-basis: calc(9 * (100% / 12));
  }

  .xs\:o-grid__col--span-10 {
    flex-basis: calc(10 * (100% / 12));
  }

  .xs\:o-grid__col--span-11 {
    flex-basis: calc(11 * (100% / 12));
  }

  .xs\:o-grid__col--span-12 {
    flex-basis: calc(12 * (100% / 12));
  }

  .xs\:o-grid--gap-xxs {
    margin: -0.0625rem;
  }
  .xs\:o-grid--gap-xxs > .o-grid__col {
    border-width: 0.0625rem;
  }

  .xs\:o-grid--gap-xs {
    margin: -0.125rem;
  }
  .xs\:o-grid--gap-xs > .o-grid__col {
    border-width: 0.125rem;
  }

  .xs\:o-grid--gap-s {
    margin: -0.25rem;
  }
  .xs\:o-grid--gap-s > .o-grid__col {
    border-width: 0.25rem;
  }

  .xs\:o-grid--gap-m {
    margin: -0.5rem;
  }
  .xs\:o-grid--gap-m > .o-grid__col {
    border-width: 0.5rem;
  }

  .xs\:o-grid--gap-l {
    margin: -0.75rem;
  }
  .xs\:o-grid--gap-l > .o-grid__col {
    border-width: 0.75rem;
  }

  .xs\:o-grid--gap-xl {
    margin: -1rem;
  }
  .xs\:o-grid--gap-xl > .o-grid__col {
    border-width: 1rem;
  }

  .xs\:o-grid--gap-xxl {
    margin: -1.5rem;
  }
  .xs\:o-grid--gap-xxl > .o-grid__col {
    border-width: 1.5rem;
  }

  .xs\:o-grid--gap-3xl {
    margin: -2rem;
  }
  .xs\:o-grid--gap-3xl > .o-grid__col {
    border-width: 2rem;
  }

  .xs\:o-grid--gap-4xl {
    margin: -2.5rem;
  }
  .xs\:o-grid--gap-4xl > .o-grid__col {
    border-width: 2.5rem;
  }

  .xs\:o-grid--gap-5xl {
    margin: -3rem;
  }
  .xs\:o-grid--gap-5xl > .o-grid__col {
    border-width: 3rem;
  }

  .xs\:o-grid--gap-6xl {
    margin: -3.5rem;
  }
  .xs\:o-grid--gap-6xl > .o-grid__col {
    border-width: 3.5rem;
  }
}
@media (min-width: 375px) {
  .s\:o-grid--reverse {
    flex-direction: row-reverse;
  }

  .s\:o-grid__col--span-1 {
    flex-basis: calc(1 * (100% / 12));
  }

  .s\:o-grid__col--span-2 {
    flex-basis: calc(2 * (100% / 12));
  }

  .s\:o-grid__col--span-3 {
    flex-basis: calc(3 * (100% / 12));
  }

  .s\:o-grid__col--span-4 {
    flex-basis: calc(4 * (100% / 12));
  }

  .s\:o-grid__col--span-5 {
    flex-basis: calc(5 * (100% / 12));
  }

  .s\:o-grid__col--span-6 {
    flex-basis: calc(6 * (100% / 12));
  }

  .s\:o-grid__col--span-7 {
    flex-basis: calc(7 * (100% / 12));
  }

  .s\:o-grid__col--span-8 {
    flex-basis: calc(8 * (100% / 12));
  }

  .s\:o-grid__col--span-9 {
    flex-basis: calc(9 * (100% / 12));
  }

  .s\:o-grid__col--span-10 {
    flex-basis: calc(10 * (100% / 12));
  }

  .s\:o-grid__col--span-11 {
    flex-basis: calc(11 * (100% / 12));
  }

  .s\:o-grid__col--span-12 {
    flex-basis: calc(12 * (100% / 12));
  }

  .s\:o-grid--gap-xxs {
    margin: -0.0625rem;
  }
  .s\:o-grid--gap-xxs > .o-grid__col {
    border-width: 0.0625rem;
  }

  .s\:o-grid--gap-xs {
    margin: -0.125rem;
  }
  .s\:o-grid--gap-xs > .o-grid__col {
    border-width: 0.125rem;
  }

  .s\:o-grid--gap-s {
    margin: -0.25rem;
  }
  .s\:o-grid--gap-s > .o-grid__col {
    border-width: 0.25rem;
  }

  .s\:o-grid--gap-m {
    margin: -0.5rem;
  }
  .s\:o-grid--gap-m > .o-grid__col {
    border-width: 0.5rem;
  }

  .s\:o-grid--gap-l {
    margin: -0.75rem;
  }
  .s\:o-grid--gap-l > .o-grid__col {
    border-width: 0.75rem;
  }

  .s\:o-grid--gap-xl {
    margin: -1rem;
  }
  .s\:o-grid--gap-xl > .o-grid__col {
    border-width: 1rem;
  }

  .s\:o-grid--gap-xxl {
    margin: -1.5rem;
  }
  .s\:o-grid--gap-xxl > .o-grid__col {
    border-width: 1.5rem;
  }

  .s\:o-grid--gap-3xl {
    margin: -2rem;
  }
  .s\:o-grid--gap-3xl > .o-grid__col {
    border-width: 2rem;
  }

  .s\:o-grid--gap-4xl {
    margin: -2.5rem;
  }
  .s\:o-grid--gap-4xl > .o-grid__col {
    border-width: 2.5rem;
  }

  .s\:o-grid--gap-5xl {
    margin: -3rem;
  }
  .s\:o-grid--gap-5xl > .o-grid__col {
    border-width: 3rem;
  }

  .s\:o-grid--gap-6xl {
    margin: -3.5rem;
  }
  .s\:o-grid--gap-6xl > .o-grid__col {
    border-width: 3.5rem;
  }
}
@media (min-width: 768px) {
  .m\:o-grid--reverse {
    flex-direction: row-reverse;
  }

  .m\:o-grid__col--span-1 {
    flex-basis: calc(1 * (100% / 12));
  }

  .m\:o-grid__col--span-2 {
    flex-basis: calc(2 * (100% / 12));
  }

  .m\:o-grid__col--span-3 {
    flex-basis: calc(3 * (100% / 12));
  }

  .m\:o-grid__col--span-4 {
    flex-basis: calc(4 * (100% / 12));
  }

  .m\:o-grid__col--span-5 {
    flex-basis: calc(5 * (100% / 12));
  }

  .m\:o-grid__col--span-6 {
    flex-basis: calc(6 * (100% / 12));
  }

  .m\:o-grid__col--span-7 {
    flex-basis: calc(7 * (100% / 12));
  }

  .m\:o-grid__col--span-8 {
    flex-basis: calc(8 * (100% / 12));
  }

  .m\:o-grid__col--span-9 {
    flex-basis: calc(9 * (100% / 12));
  }

  .m\:o-grid__col--span-10 {
    flex-basis: calc(10 * (100% / 12));
  }

  .m\:o-grid__col--span-11 {
    flex-basis: calc(11 * (100% / 12));
  }

  .m\:o-grid__col--span-12 {
    flex-basis: calc(12 * (100% / 12));
  }

  .m\:o-grid--gap-xxs {
    margin: -0.0625rem;
  }
  .m\:o-grid--gap-xxs > .o-grid__col {
    border-width: 0.0625rem;
  }

  .m\:o-grid--gap-xs {
    margin: -0.125rem;
  }
  .m\:o-grid--gap-xs > .o-grid__col {
    border-width: 0.125rem;
  }

  .m\:o-grid--gap-s {
    margin: -0.25rem;
  }
  .m\:o-grid--gap-s > .o-grid__col {
    border-width: 0.25rem;
  }

  .m\:o-grid--gap-m {
    margin: -0.5rem;
  }
  .m\:o-grid--gap-m > .o-grid__col {
    border-width: 0.5rem;
  }

  .m\:o-grid--gap-l {
    margin: -0.75rem;
  }
  .m\:o-grid--gap-l > .o-grid__col {
    border-width: 0.75rem;
  }

  .m\:o-grid--gap-xl {
    margin: -1rem;
  }
  .m\:o-grid--gap-xl > .o-grid__col {
    border-width: 1rem;
  }

  .m\:o-grid--gap-xxl {
    margin: -1.5rem;
  }
  .m\:o-grid--gap-xxl > .o-grid__col {
    border-width: 1.5rem;
  }

  .m\:o-grid--gap-3xl {
    margin: -2rem;
  }
  .m\:o-grid--gap-3xl > .o-grid__col {
    border-width: 2rem;
  }

  .m\:o-grid--gap-4xl {
    margin: -2.5rem;
  }
  .m\:o-grid--gap-4xl > .o-grid__col {
    border-width: 2.5rem;
  }

  .m\:o-grid--gap-5xl {
    margin: -3rem;
  }
  .m\:o-grid--gap-5xl > .o-grid__col {
    border-width: 3rem;
  }

  .m\:o-grid--gap-6xl {
    margin: -3.5rem;
  }
  .m\:o-grid--gap-6xl > .o-grid__col {
    border-width: 3.5rem;
  }
}
@media (min-width: 1024px) {
  .l\:o-grid--reverse {
    flex-direction: row-reverse;
  }

  .l\:o-grid__col--span-1 {
    flex-basis: calc(1 * (100% / 12));
  }

  .l\:o-grid__col--span-2 {
    flex-basis: calc(2 * (100% / 12));
  }

  .l\:o-grid__col--span-3 {
    flex-basis: calc(3 * (100% / 12));
  }

  .l\:o-grid__col--span-4 {
    flex-basis: calc(4 * (100% / 12));
  }

  .l\:o-grid__col--span-5 {
    flex-basis: calc(5 * (100% / 12));
  }

  .l\:o-grid__col--span-6 {
    flex-basis: calc(6 * (100% / 12));
  }

  .l\:o-grid__col--span-7 {
    flex-basis: calc(7 * (100% / 12));
  }

  .l\:o-grid__col--span-8 {
    flex-basis: calc(8 * (100% / 12));
  }

  .l\:o-grid__col--span-9 {
    flex-basis: calc(9 * (100% / 12));
  }

  .l\:o-grid__col--span-10 {
    flex-basis: calc(10 * (100% / 12));
  }

  .l\:o-grid__col--span-11 {
    flex-basis: calc(11 * (100% / 12));
  }

  .l\:o-grid__col--span-12 {
    flex-basis: calc(12 * (100% / 12));
  }

  .l\:o-grid--gap-xxs {
    margin: -0.0625rem;
  }
  .l\:o-grid--gap-xxs > .o-grid__col {
    border-width: 0.0625rem;
  }

  .l\:o-grid--gap-xs {
    margin: -0.125rem;
  }
  .l\:o-grid--gap-xs > .o-grid__col {
    border-width: 0.125rem;
  }

  .l\:o-grid--gap-s {
    margin: -0.25rem;
  }
  .l\:o-grid--gap-s > .o-grid__col {
    border-width: 0.25rem;
  }

  .l\:o-grid--gap-m {
    margin: -0.5rem;
  }
  .l\:o-grid--gap-m > .o-grid__col {
    border-width: 0.5rem;
  }

  .l\:o-grid--gap-l {
    margin: -0.75rem;
  }
  .l\:o-grid--gap-l > .o-grid__col {
    border-width: 0.75rem;
  }

  .l\:o-grid--gap-xl {
    margin: -1rem;
  }
  .l\:o-grid--gap-xl > .o-grid__col {
    border-width: 1rem;
  }

  .l\:o-grid--gap-xxl {
    margin: -1.5rem;
  }
  .l\:o-grid--gap-xxl > .o-grid__col {
    border-width: 1.5rem;
  }

  .l\:o-grid--gap-3xl {
    margin: -2rem;
  }
  .l\:o-grid--gap-3xl > .o-grid__col {
    border-width: 2rem;
  }

  .l\:o-grid--gap-4xl {
    margin: -2.5rem;
  }
  .l\:o-grid--gap-4xl > .o-grid__col {
    border-width: 2.5rem;
  }

  .l\:o-grid--gap-5xl {
    margin: -3rem;
  }
  .l\:o-grid--gap-5xl > .o-grid__col {
    border-width: 3rem;
  }

  .l\:o-grid--gap-6xl {
    margin: -3.5rem;
  }
  .l\:o-grid--gap-6xl > .o-grid__col {
    border-width: 3.5rem;
  }
}
@media (min-width: 1280px) {
  .xl\:o-grid--reverse {
    flex-direction: row-reverse;
  }

  .xl\:o-grid__col--span-1 {
    flex-basis: calc(1 * (100% / 12));
  }

  .xl\:o-grid__col--span-2 {
    flex-basis: calc(2 * (100% / 12));
  }

  .xl\:o-grid__col--span-3 {
    flex-basis: calc(3 * (100% / 12));
  }

  .xl\:o-grid__col--span-4 {
    flex-basis: calc(4 * (100% / 12));
  }

  .xl\:o-grid__col--span-5 {
    flex-basis: calc(5 * (100% / 12));
  }

  .xl\:o-grid__col--span-6 {
    flex-basis: calc(6 * (100% / 12));
  }

  .xl\:o-grid__col--span-7 {
    flex-basis: calc(7 * (100% / 12));
  }

  .xl\:o-grid__col--span-8 {
    flex-basis: calc(8 * (100% / 12));
  }

  .xl\:o-grid__col--span-9 {
    flex-basis: calc(9 * (100% / 12));
  }

  .xl\:o-grid__col--span-10 {
    flex-basis: calc(10 * (100% / 12));
  }

  .xl\:o-grid__col--span-11 {
    flex-basis: calc(11 * (100% / 12));
  }

  .xl\:o-grid__col--span-12 {
    flex-basis: calc(12 * (100% / 12));
  }

  .xl\:o-grid--gap-xxs {
    margin: -0.0625rem;
  }
  .xl\:o-grid--gap-xxs > .o-grid__col {
    border-width: 0.0625rem;
  }

  .xl\:o-grid--gap-xs {
    margin: -0.125rem;
  }
  .xl\:o-grid--gap-xs > .o-grid__col {
    border-width: 0.125rem;
  }

  .xl\:o-grid--gap-s {
    margin: -0.25rem;
  }
  .xl\:o-grid--gap-s > .o-grid__col {
    border-width: 0.25rem;
  }

  .xl\:o-grid--gap-m {
    margin: -0.5rem;
  }
  .xl\:o-grid--gap-m > .o-grid__col {
    border-width: 0.5rem;
  }

  .xl\:o-grid--gap-l {
    margin: -0.75rem;
  }
  .xl\:o-grid--gap-l > .o-grid__col {
    border-width: 0.75rem;
  }

  .xl\:o-grid--gap-xl {
    margin: -1rem;
  }
  .xl\:o-grid--gap-xl > .o-grid__col {
    border-width: 1rem;
  }

  .xl\:o-grid--gap-xxl {
    margin: -1.5rem;
  }
  .xl\:o-grid--gap-xxl > .o-grid__col {
    border-width: 1.5rem;
  }

  .xl\:o-grid--gap-3xl {
    margin: -2rem;
  }
  .xl\:o-grid--gap-3xl > .o-grid__col {
    border-width: 2rem;
  }

  .xl\:o-grid--gap-4xl {
    margin: -2.5rem;
  }
  .xl\:o-grid--gap-4xl > .o-grid__col {
    border-width: 2.5rem;
  }

  .xl\:o-grid--gap-5xl {
    margin: -3rem;
  }
  .xl\:o-grid--gap-5xl > .o-grid__col {
    border-width: 3rem;
  }

  .xl\:o-grid--gap-6xl {
    margin: -3.5rem;
  }
  .xl\:o-grid--gap-6xl > .o-grid__col {
    border-width: 3.5rem;
  }
}
.o-media {
  width: 100%;
  height: 0;
  position: relative;
}
.o-media iframe {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.o-media--1\:1 {
  padding-top: 100%;
}
.o-media--16\:9 {
  padding-top: 56.25%;
}

.o-wrapper {
  margin: 0 auto;
  max-width: 1920px;
  padding: 0 10px;
  position: relative;
  width: 100%;
}
@media (min-width: 768px) {
  .o-wrapper {
    padding: 0 3rem;
  }
}
.o-wrapper__inner {
  padding: 0 10px;
}
@media (min-width: 768px) {
  .o-wrapper__inner {
    margin: 0 auto;
    width: 90%;
  }
}

.c-bio {
  --equinox: 0;
  --equinox-pos: 0;
  position: relative;
}
@media (max-width: 1023px) {
  .c-bio .o-wrapper__inner {
    padding: 0;
  }
}
.c-bio__photo {
  background: #000;
  height: 100vw;
  margin: -3rem 0 -128px -10px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  width: 100vw;
}
@media (min-width: 1024px) {
  .c-bio__photo {
    bottom: -7rem;
    height: auto;
    left: 0;
    margin: 0;
    position: absolute;
    top: -7rem;
    width: 50%;
  }
}
.c-bio__photoImg {
  height: 150%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  transform: translate3d(0, calc(var(--equinox-pos) * -33.33333%), 0);
  width: 100%;
  will-change: transform;
}
.c-bio__content {
  background-color: rgb(var(--color-bg));
  box-shadow: 0 24px 48px rgba(0, 0, 0, 0.15);
  margin-bottom: -5rem;
  padding: 2rem 1.5rem;
  position: relative;
  transform: translate3d(0, calc(3.5rem * var(--equinox)), 0);
  will-change: transform;
  z-index: 2;
}
@media (min-width: 1024px) {
  .c-bio__content {
    border-left: 4rem solid rgb(var(--color-bg));
    padding: 5rem 4rem 5rem 0;
    margin: 0 0 0 calc(50% - 4rem);
  }
}
.c-bio__heading {
  margin-bottom: 3rem;
}
.c-bio__body {
  margin-bottom: 2rem;
}
@media (min-width: 1024px) {
  .c-bio__body {
    font-size: var(--font-size-l);
    margin-bottom: 3rem;
  }
}
.c-bio__buttons {
  display: flex;
}
@media (max-width: 1023px) {
  .c-bio__buttons .c-button {
    flex: 0 0 100%;
  }
}

.c-hero {
  background: rgb(var(--color-bg));
  color: rgb(var(--color-text));
  position: relative;
}
@media (min-width: 1024px) {
  .c-hero {
    background: transparent;
  }
}
.c-hero__logo {
  position: sticky;
  pointer-events: none;
  user-select: none;
  top: 0;
}
.c-hero__logo--jaaf {
  z-index: 2;
}
.c-hero__logo--meubels {
  background: rgb(var(--color-bg));
  box-shadow: 50vw 0 0 rgb(var(--color-bg)), -50vw 0 0 rgb(var(--color-bg));
  z-index: 1;
  margin-top: -50%;
}
.c-hero__wrapper {
  padding-bottom: 21.875%;
  margin-top: -15.75%;
  position: relative;
  z-index: 2;
}
@media (max-width: 1023px) {
  .c-hero__wrapper .o-wrapper__inner {
    padding: 0;
  }
}
.c-hero__inner {
  background: #000;
  color: rgb(var(--color-text));
  position: relative;
}
@media (min-width: 1024px) {
  .c-hero__inner {
    box-shadow: 0 24px 48px rgba(0, 0, 0, 0.25);
    padding: 5rem 5rem 6rem 5rem;
  }
}
.c-hero__backdropWrapper {
  height: 0;
  padding-bottom: 75%;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
@media (min-width: 1024px) {
  .c-hero__backdropWrapper {
    height: 100%;
    left: 0;
    padding-bottom: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
@media (max-width: 1023px) {
  .c-hero__backdropWrapper::after {
    background-image: url("/assets/img/hero-fade.png");
    background-repeat: repeat-x;
    background-size: auto 96px;
    bottom: 0;
    content: "";
    height: 96px;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 2;
  }
}
.c-hero__backdrop {
  background-image: url("/assets/img/hero3.jpg");
  background-position: 70% center;
  background-repeat: no-repeat;
  background-size: cover;
  top: 0;
  height: 150%;
  left: 0;
  position: absolute;
  transform: translate3d(0, calc(var(--equinox-pos) * -33.33333%), 0);
  z-index: 1;
  width: 100%;
  will-change: transform;
}
@media (min-width: 1024px) {
  .c-hero__backdrop {
    background-position: center;
  }
}
.c-hero__content {
  margin-top: -25vw;
  padding: 1.5rem;
  position: relative;
  z-index: 2;
}
@media (min-width: 1024px) {
  .c-hero__content {
    margin-top: 0;
    padding: 0;
    width: 50%;
  }
}
.c-hero__heading {
  margin-bottom: 2rem;
}
@media (min-width: 1024px) {
  .c-hero__heading {
    margin-bottom: 5rem;
  }
}
.c-hero__body {
  margin-bottom: 2rem;
}
@media (min-width: 1024px) {
  .c-hero__body {
    font-size: var(--font-size-l);
    margin-bottom: 3rem;
  }
}
.c-hero__buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 1.5rem;
}
@media (max-width: 1023px) {
  .c-hero__buttons .c-button {
    flex: 0 0 100%;
  }
}
@media (min-width: 1024px) {
  .c-hero__buttons {
    margin-bottom: 2rem;
  }
}
.c-hero__instagram {
  align-items: center;
  display: flex;
  gap: 8px;
  font-weight: 500;
}
.c-hero__instagramIcon {
  fill: rgb(var(--color-link));
  height: 20px;
  width: 20px;
}

.c-project__description {
  font-size: var(--font-size-xs);
  padding: 10px 10px 20px 10px;
}
@media (min-width: 1024px) {
  .c-project__description {
    font-size: var(--font-size-s);
    padding: 1rem 0 2rem 0;
  }
}
.c-project__imageZoom {
  cursor: zoom-in;
  display: block;
}

@media (max-width: 1023px) {
  .c-projects__bleed {
    margin: 0 -10px;
  }
}
.c-projects__list {
  column-count: 2;
  column-gap: 0.5rem;
  page-break-inside: avoid;
}
@media (min-width: 768px) {
  .c-projects__list {
    column-gap: 1.5rem;
    column-count: 3;
  }
}
@media (min-width: 1024px) {
  .c-projects__list {
    column-count: 4;
  }
}
.c-projects__listItem {
  break-inside: avoid-column;
  margin-bottom: 0.5rem;
  position: relative;
}

.c-review__body {
  margin-bottom: 1.5rem;
}
@media (min-width: 1024px) {
  .c-review__body {
    font-size: var(--font-size-l);
  }
}

.c-reviews {
  padding: 0 !important;
  position: relative;
}
@media (min-width: 1024px) {
  .c-reviews {
    padding: 0 0 7rem 0 !important;
  }
}
@media (min-width: 1024px) {
  .c-reviews .o-grid {
    flex-direction: row-reverse !important;
  }
}
.c-reviews__photos {
  display: flex;
}
.c-reviews__photo {
  flex: 0 0 50%;
}
.c-reviews__photoMobile {
  margin: 0 -10px;
}
@media (min-width: 1024px) {
  .c-reviews__photoMobile {
    margin: 0;
    position: sticky;
    top: 0.5rem;
  }
}
@media (max-width: 1023px) {
  .c-reviews__photo--1, .c-reviews__photo--2 {
    display: none;
  }
}
.c-reviews__photo--2 {
  margin-bottom: 0.5rem;
}
@media (max-width: 1023px) {
  .c-reviews__photo--3 {
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
    position: relative;
  }
  .c-reviews__photo--3 img {
    height: 150%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    transform: translate3d(0, calc(var(--equinox-pos) * -33.33333%), 0);
    width: 100%;
    will-change: transform;
  }
}
.c-reviews__content {
  padding: 3rem 10px;
}
@media (min-width: 1024px) {
  .c-reviews__content {
    float: right;
    padding: 7rem 4rem 0 10px;
    width: 90%;
  }
}
.c-reviews__listItem:not(:last-child)::after {
  background: rgb(var(--color));
  content: "";
  display: block;
  height: 3px;
  margin: 1.5rem auto 3rem;
  width: 80px;
}

.c-button {
  font-family: var(--font-1);
  letter-spacing: var(--font-1-spacing);
  appearance: none;
  background: transparent;
  border: none;
  cursor: pointer;
  color: rgb(var(--color-button-text));
  display: inline-block;
  font-size: var(--font-size-m);
  font-weight: 500;
  height: 48px;
  line-height: 20px;
  padding: 14px 24px;
  text-align: center;
  text-decoration: none;
  transition: background-color var(--duration-slow) cubic-bezier(0.25, 1, 0.25, 1);
  user-select: none;
  white-space: nowrap;
}
@media (min-width: 1024px) {
  .c-button {
    height: 56px;
    padding: 18px 40px;
  }
}
.c-button:active {
  padding-bottom: 13px;
  padding-top: 15px;
}
@media (min-width: 1024px) {
  .c-button:active {
    padding-bottom: 17px;
    padding-top: 19px;
  }
}
.c-button:hover {
  background: rgb(var(--color-button-hover));
  color: rgb(var(--color-bg));
  text-decoration: none;
}
.c-button__icon {
  display: block;
  fill: rgb(var(--color-bg));
  float: left;
  margin-right: 4px;
  position: relative;
}
.c-button--inverted {
  background: rgb(var(--color-button-inverted));
  box-shadow: none;
  color: rgb(var(--color-3));
  font-weight: 600;
}
.c-button--inverted:hover {
  box-shadow: none;
}
.c-button--primary {
  background: rgb(var(--color-button-primary));
  color: rgb(var(--color-button-primary-text));
  font-weight: 500;
}
.c-button--primary:hover {
  background: rgb(var(--color-button-primary-hover));
  color: rgb(var(--color-button-primary-text-hover));
}

.c-footer {
  background: rgb(var(--color));
  color: rgb(var(--color-text));
  font-size: var(--font-size-xs);
  padding: 5rem 0;
}
@media (min-width: 1024px) {
  .c-footer {
    font-size: var(--font-size-s);
  }
}

.c-formatted {
  font-family: var(--font-2);
  letter-spacing: var(--font-2-spacing);
}
.c-formatted a {
  box-shadow: inset 0 -1px 0 rgb(var(--color-link)/0%);
  color: rgb(var(--color-link));
  transition: var(--duration-fast) ease;
}
.c-formatted a:hover {
  color: rgb(var(--color-link-hover));
  box-shadow: inset 0 -1px 0 rgb(var(--color-link)/50%);
}

.c-page {
  margin: 0 auto;
  max-width: 1600px;
  position: relative;
  width: 100%;
}
.c-page__spacer {
  height: 0;
  padding-bottom: 20.73459%;
  width: 100%;
}

.c-section {
  color: rgb(var(--color-text));
  padding: 3rem 0;
  position: relative;
}
@media (min-width: 1024px) {
  .c-section {
    padding: 7rem 0;
  }
}
.c-section--color {
  background: rgb(var(--color));
}
.c-section__header {
  font-size: var(--font-size-l);
  margin-bottom: 2rem;
}
@media (min-width: 1024px) {
  .c-section__header {
    font-size: var(--font-size-xl);
    margin-bottom: 4rem;
    width: 50%;
  }
}

@keyframes tabPanel {
  0% {
    opacity: 0;
    transform: translateY(32px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
.c-tabs__tabList {
  display: flex;
  gap: 1.5rem;
  margin-bottom: 4rem;
}
.c-tabs__tab {
  font-family: var(--font-1);
  letter-spacing: var(--font-1-spacing);
  appearance: none;
  background: transparent;
  border: none;
  color: rgb(var(--color)/50%);
  cursor: pointer;
  font-size: var(--font-size-3xl);
  margin: 0;
  padding: 0;
  position: relative;
  transition: var(--duration-medium) ease;
}
.c-tabs__tab::after {
  background: rgb(var(--color));
  bottom: -2px;
  left: 0;
  position: absolute;
  content: "";
  height: 2px;
  transform: scaleX(0);
  transform-origin: 0 0;
  transition: var(--duration-medium) ease;
  width: 100%;
}
.c-tabs__tab:first-child::after {
  transform-origin: 100% 0;
}
.c-tabs__tab:hover {
  color: rgba(var(--color), 1);
}
.c-tabs__tab[aria-selected=true] {
  color: rgba(var(--color), 1);
}
.c-tabs__tab[aria-selected=true]::after {
  transform: scaleX(1);
}
.c-tabs__tabPanel {
  animation: tabPanel var(--duration-slow) both ease;
  will-change: opacity, transform;
}

.c-zoom__target {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: 1;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}
@media (min-width: 1024px) {
  .c-zoom__target {
    padding: 0.5rem;
  }
}
.c-zoom__target img {
  max-height: 100%;
  max-width: 100%;
}
.c-zoom__overlay {
  background: rgb(var(--color-bg));
  cursor: zoom-out;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  will-change: opacity;
  z-index: 999;
}
.c-zoom__container {
  position: absolute;
  z-index: 1000;
}
.c-zoom__container img {
  mix-blend-mode: multiply;
  cursor: zoom-out;
  max-width: none;
  transform: translate3d(0, 0, 0);
  transform-origin: left top;
  width: 100%;
  will-change: transform;
}

.u-delay {
  --delay: 100ms;
}
.u-delay:nth-child(1) {
  animation-delay: calc(0 * var(--delay));
  transition-delay: calc(0 * var(--delay));
}
.u-delay:nth-child(2) {
  animation-delay: calc(1 * var(--delay));
  transition-delay: calc(1 * var(--delay));
}
.u-delay:nth-child(3) {
  animation-delay: calc(2 * var(--delay));
  transition-delay: calc(2 * var(--delay));
}
.u-delay:nth-child(4) {
  animation-delay: calc(3 * var(--delay));
  transition-delay: calc(3 * var(--delay));
}
.u-delay:nth-child(5) {
  animation-delay: calc(4 * var(--delay));
  transition-delay: calc(4 * var(--delay));
}
.u-delay:nth-child(6) {
  animation-delay: calc(5 * var(--delay));
  transition-delay: calc(5 * var(--delay));
}
.u-delay:nth-child(7) {
  animation-delay: calc(6 * var(--delay));
  transition-delay: calc(6 * var(--delay));
}
.u-delay:nth-child(8) {
  animation-delay: calc(7 * var(--delay));
  transition-delay: calc(7 * var(--delay));
}
.u-delay:nth-child(9) {
  animation-delay: calc(8 * var(--delay));
  transition-delay: calc(8 * var(--delay));
}
.u-delay:nth-child(10) {
  animation-delay: calc(9 * var(--delay));
  transition-delay: calc(9 * var(--delay));
}

.u-m-none {
  margin-bottom: 0 !important;
}

.u-m-xxs {
  margin-bottom: 0.125rem !important;
}

.u-m-xs {
  margin-bottom: 0.25rem !important;
}

.u-m-s {
  margin-bottom: 0.5rem !important;
}

.u-m-m {
  margin-bottom: 1rem !important;
}

.u-m-l {
  margin-bottom: 1.5rem !important;
}

.u-m-xl {
  margin-bottom: 2rem !important;
}

.u-m-xxl {
  margin-bottom: 3rem !important;
}

.u-m-3xl {
  margin-bottom: 4rem !important;
}

.u-m-4xl {
  margin-bottom: 5rem !important;
}

.u-m-5xl {
  margin-bottom: 6rem !important;
}

.u-m-6xl {
  margin-bottom: 7rem !important;
}

@media (min-width: 320px) {
  .xs\:u-m-none {
    margin-bottom: 0 !important;
  }

  .xs\:u-m-xxs {
    margin-bottom: 0.125rem !important;
  }

  .xs\:u-m-xs {
    margin-bottom: 0.25rem !important;
  }

  .xs\:u-m-s {
    margin-bottom: 0.5rem !important;
  }

  .xs\:u-m-m {
    margin-bottom: 1rem !important;
  }

  .xs\:u-m-l {
    margin-bottom: 1.5rem !important;
  }

  .xs\:u-m-xl {
    margin-bottom: 2rem !important;
  }

  .xs\:u-m-xxl {
    margin-bottom: 3rem !important;
  }

  .xs\:u-m-3xl {
    margin-bottom: 4rem !important;
  }

  .xs\:u-m-4xl {
    margin-bottom: 5rem !important;
  }

  .xs\:u-m-5xl {
    margin-bottom: 6rem !important;
  }

  .xs\:u-m-6xl {
    margin-bottom: 7rem !important;
  }
}
@media (min-width: 375px) {
  .s\:u-m-none {
    margin-bottom: 0 !important;
  }

  .s\:u-m-xxs {
    margin-bottom: 0.125rem !important;
  }

  .s\:u-m-xs {
    margin-bottom: 0.25rem !important;
  }

  .s\:u-m-s {
    margin-bottom: 0.5rem !important;
  }

  .s\:u-m-m {
    margin-bottom: 1rem !important;
  }

  .s\:u-m-l {
    margin-bottom: 1.5rem !important;
  }

  .s\:u-m-xl {
    margin-bottom: 2rem !important;
  }

  .s\:u-m-xxl {
    margin-bottom: 3rem !important;
  }

  .s\:u-m-3xl {
    margin-bottom: 4rem !important;
  }

  .s\:u-m-4xl {
    margin-bottom: 5rem !important;
  }

  .s\:u-m-5xl {
    margin-bottom: 6rem !important;
  }

  .s\:u-m-6xl {
    margin-bottom: 7rem !important;
  }
}
@media (min-width: 768px) {
  .m\:u-m-none {
    margin-bottom: 0 !important;
  }

  .m\:u-m-xxs {
    margin-bottom: 0.125rem !important;
  }

  .m\:u-m-xs {
    margin-bottom: 0.25rem !important;
  }

  .m\:u-m-s {
    margin-bottom: 0.5rem !important;
  }

  .m\:u-m-m {
    margin-bottom: 1rem !important;
  }

  .m\:u-m-l {
    margin-bottom: 1.5rem !important;
  }

  .m\:u-m-xl {
    margin-bottom: 2rem !important;
  }

  .m\:u-m-xxl {
    margin-bottom: 3rem !important;
  }

  .m\:u-m-3xl {
    margin-bottom: 4rem !important;
  }

  .m\:u-m-4xl {
    margin-bottom: 5rem !important;
  }

  .m\:u-m-5xl {
    margin-bottom: 6rem !important;
  }

  .m\:u-m-6xl {
    margin-bottom: 7rem !important;
  }
}
@media (min-width: 1024px) {
  .l\:u-m-none {
    margin-bottom: 0 !important;
  }

  .l\:u-m-xxs {
    margin-bottom: 0.125rem !important;
  }

  .l\:u-m-xs {
    margin-bottom: 0.25rem !important;
  }

  .l\:u-m-s {
    margin-bottom: 0.5rem !important;
  }

  .l\:u-m-m {
    margin-bottom: 1rem !important;
  }

  .l\:u-m-l {
    margin-bottom: 1.5rem !important;
  }

  .l\:u-m-xl {
    margin-bottom: 2rem !important;
  }

  .l\:u-m-xxl {
    margin-bottom: 3rem !important;
  }

  .l\:u-m-3xl {
    margin-bottom: 4rem !important;
  }

  .l\:u-m-4xl {
    margin-bottom: 5rem !important;
  }

  .l\:u-m-5xl {
    margin-bottom: 6rem !important;
  }

  .l\:u-m-6xl {
    margin-bottom: 7rem !important;
  }
}
@media (min-width: 1280px) {
  .xl\:u-m-none {
    margin-bottom: 0 !important;
  }

  .xl\:u-m-xxs {
    margin-bottom: 0.125rem !important;
  }

  .xl\:u-m-xs {
    margin-bottom: 0.25rem !important;
  }

  .xl\:u-m-s {
    margin-bottom: 0.5rem !important;
  }

  .xl\:u-m-m {
    margin-bottom: 1rem !important;
  }

  .xl\:u-m-l {
    margin-bottom: 1.5rem !important;
  }

  .xl\:u-m-xl {
    margin-bottom: 2rem !important;
  }

  .xl\:u-m-xxl {
    margin-bottom: 3rem !important;
  }

  .xl\:u-m-3xl {
    margin-bottom: 4rem !important;
  }

  .xl\:u-m-4xl {
    margin-bottom: 5rem !important;
  }

  .xl\:u-m-5xl {
    margin-bottom: 6rem !important;
  }

  .xl\:u-m-6xl {
    margin-bottom: 7rem !important;
  }
}
.u-text-align-center {
  text-align: center;
}

.u-text-align-left {
  text-align: left;
}

.u-text-align-right {
  text-align: right;
}

.u-text-case-lower {
  text-transform: lowercase;
}

.u-text-case-upper {
  text-transform: uppercase;
}

.u-text-color-hc {
  color: var(--color-text-hc);
}

.u-text-color-lc {
  color: var(--color-text-lc);
}
.u-text-color-lc a {
  color: var(--color-text-lc);
  text-decoration: underline;
}
.u-text-color-lc a:hover {
  color: var(--color-link-hover);
}

.u-text-mask {
  display: inline;
  margin: 0 2px;
  filter: blur(2px);
  opacity: 0.5;
}

.u-text-mono {
  font-family: var(--font-mono);
}

.u-text-trunc {
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.u-text-size-s {
  font-size: var(--font-size-s);
}

.u-hide {
  display: none;
}

.u-hideEmpty:empty {
  display: none;
}

@media (max-width: 319px) {
  .xs\:u-show {
    display: none;
  }
}
@media (min-width: 320px) {
  .xs\:u-hide {
    display: none;
  }
}
@media (max-width: 374px) {
  .s\:u-show,
.m\:u-show,
.l\:u-show,
.xl\:u-show {
    display: none;
  }
}
@media (min-width: 375px) {
  .s\:u-hide {
    display: none;
  }
}
@media (max-width: 767px) {
  .m\:u-show,
.l\:u-show,
.xl\:u-show {
    display: none;
  }
}
@media (min-width: 768px) {
  .m\:u-hide {
    display: none;
  }
}
@media (max-width: 1023px) {
  .l\:u-show,
.xl\:u-show {
    display: none;
  }
}
@media (min-width: 1024px) {
  .l\:u-hide {
    display: none;
  }
}
@media (max-width: 1279px) {
  .xl\:u-show {
    display: none;
  }
}
@media (min-width: 1280px) {
  .xl\:u-hide {
    display: none;
  }
}