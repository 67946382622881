.c-button {
    @include font-1;
    appearance: none;
    background: transparent;
    border: none;
    cursor: pointer;
    color: rgb(var(--color-button-text));
    display: inline-block;
    font-size: var(--font-size-m);
    font-weight: 500;
    height: 48px;
    line-height: 20px;
    padding: 14px 24px;
    text-align: center;
    text-decoration: none;
    transition: background-color var(--duration-slow) cubic-bezier(.25, 1, .25, 1);
    user-select: none;
    white-space: nowrap;

    @include screen-min(l) {
        height: 56px;
        padding: 18px 40px;
    }

    &:active {
        padding-bottom: 13px;
        padding-top: 15px;

        @include screen-min(l) {
            padding-bottom: 17px;
            padding-top: 19px;
        }
    }

    &:hover {
        background: rgb(var(--color-button-hover));
        color: rgb(var(--color-bg));
        text-decoration: none;
    }

    &__icon {
        display: block;
        fill: rgb(var(--color-bg));
        float: left;
        margin-right: 4px;
        position: relative;
    }

    &--inverted {
        background: rgb(var(--color-button-inverted));
        box-shadow: none;
        color: rgb(var(--color-3));
        font-weight: 600;

        &:hover {
            box-shadow: none;
        }
    }
    
    &--primary {
        background: rgb(var(--color-button-primary));
        color: rgb(var(--color-button-primary-text));
        font-weight: 500;

        &:hover {
            background: rgb(var(--color-button-primary-hover));
            color: rgb(var(--color-button-primary-text-hover));
        }
    }
}