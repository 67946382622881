*,
*::after,
*::before {
    @include font-1;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    outline: none;
}

::selection {
    background: rgb(var(--color));
    color: #FFF;
}