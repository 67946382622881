.c-project {
    &__description {
        font-size: var(--font-size-xs);
        padding: 10px 10px 20px 10px;

        @include screen-min(l) {
            font-size: var(--font-size-s);
            padding: space(m) 0 space(xl) 0;
        }
    }

    &__imageZoom {
        cursor: zoom-in;
        display: block;
    }
}