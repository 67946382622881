.c-page {
    margin: 0 auto;
    max-width: 1600px;
    position: relative;
    width: 100%;

    &__spacer {
        height: 0;
        padding-bottom: 20.73459%;
        width: 100%;
    }
}