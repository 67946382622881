.c-formatted {
    @include font-2;

    a {
        box-shadow: inset 0 -1px 0 rgb(var(--color-link) / 0%);
        color: rgb(var(--color-link));
        transition: var(--duration-fast) ease;

        &:hover {
            color: rgb(var(--color-link-hover));
            box-shadow: inset 0 -1px 0 rgb(var(--color-link) / 50%);
        }
    }
}