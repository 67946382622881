.c-hero {
    background: rgb(var(--color-bg));
    color: rgb(var(--color-text));
    position: relative;

    @include screen-min(l) {
        background: transparent;
    }

    &__logo {
        position: sticky;
        pointer-events: none;
        user-select: none;
        top: 0;

        &--jaaf {
            z-index: 2;
        }
        
        &--meubels {
            background: rgb(var(--color-bg));
            box-shadow: 50vw 0 0 rgb(var(--color-bg)), -50vw 0 0 rgb(var(--color-bg));
            z-index: 1;
            margin-top: -50%;
        }
    }

    &__wrapper {
        padding-bottom: 21.875%;
        margin-top: -15.75%;
        position: relative;
        z-index: 2;

        .o-wrapper__inner {
            @include screen-max(l) {
                padding: 0;
            }
        }
    }

    &__inner {
        background: #000;
        color: rgb(var(--color-text));
        position: relative;

        @include screen-min(l) {
            box-shadow: 0 24px 48px rgba(0, 0, 0, 0.25);
            padding: space(4xl) space(4xl) space(5xl) space(4xl);
        }
    }

    &__backdropWrapper {
        height: 0;
        padding-bottom: 75%;
        overflow: hidden;
        position: relative;
        z-index: 1;

        @include screen-min(l) {
            height: 100%;
            left: 0;
            padding-bottom: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }

        @include screen-max(l) {
            &::after {
                background-image: url('/assets/img/hero-fade.png');
                background-repeat: repeat-x;
                background-size: auto 96px;
                bottom: 0;
                content: '';
                height: 96px;
                left: 0;
                position: absolute;
                width: 100%;
                z-index: 2;
            }
        }
    }

    &__backdrop {
        background-image: url('/assets/img/hero3.jpg');
        background-position: 70% center;
        background-repeat: no-repeat;
        background-size: cover;
        top: 0;
        height: 150%;
        left: 0;
        position: absolute;
        transform: translate3d(0, calc(var(--equinox-pos) * -33.33333%), 0);
        z-index: 1;
        width: 100%;
        will-change: transform;

        @include screen-min(l) {
            background-position: center;
        }
    }

    &__content {
        margin-top: -25vw;
        padding: space(l);
        position: relative;
        z-index: 2;
        
        @include screen-min(l) {
            margin-top: 0;
            padding: 0;
            width: 50%;
        }
    }

    &__heading {
        margin-bottom: space(xl);

        @include screen-min(l) {
            margin-bottom: space(4xl);
        }
    }

    &__body {
        margin-bottom: space(xl);

        @include screen-min(l) {
            font-size: var(--font-size-l);
            margin-bottom: space(xxl);
        }
    }

    &__buttons {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        margin-bottom: space(l);

        @include screen-max(l) {
            .c-button {
                flex: 0 0 100%;
            }
        }

        @include screen-min(l) {
            margin-bottom: space(xl);
        }
    }

    &__instagram {
        align-items: center;
        display: flex;
        gap: 8px;
        font-weight: 500;
    }

    &__instagramIcon {
        fill: rgb(var(--color-link));
        height: 20px;
        width: 20px;
    }
}