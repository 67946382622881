.c-zoom {
    &__target {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        left: 0;
        opacity: 1;
        pointer-events: none;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 1000;

        @include screen-min(l) {
            padding: space(s);
        }

        img {
            max-height: 100%;
            max-width: 100%;
        }
    }

    &__overlay {
        background: rgb(var(--color-bg));
        cursor: zoom-out;
        height: 100%;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
        will-change: opacity;
        z-index: 999;
    }

    &__container {
        position: absolute;
        z-index: 1000;

        img {
            mix-blend-mode: multiply;
            cursor: zoom-out;
            max-width: none;
            transform: translate3d(0, 0, 0);
            transform-origin: left top;
            width: 100%;
            will-change: transform;
        }
    }
}