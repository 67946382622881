body {
    @include font-1;
    background: rgb(var(--color-bg));
    color: rgb(var(--color-text));
    line-height: var(--line-height-m);
    text-rendering: optimizelegibility;

    &.is-loading,
    &.is-loading * {
        cursor: wait;
        pointer-events: none;
    }
}