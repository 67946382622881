.c-bio {
    --equinox: 0;
    --equinox-pos: 0;
    position: relative;

    .o-wrapper__inner {
        @include screen-max(l) {
            padding: 0;
        }
    }

    &__photo {
        background: #000;
        height: 100vw;
        margin: space(xxl, -1) 0 -128px -10px;
        overflow: hidden;
        position: relative;
        z-index: 1;
        width: 100vw;

        @include screen-min(l) {
            bottom: space(6xl, -1);
            height: auto;
            left: 0;
            margin: 0;
            position: absolute;
            top: space(6xl, -1);
            width: 50%;
        }
    }

    &__photoImg {
        height: 150%;
        left: 0;
        object-fit: cover;
        position: absolute;
        top: 0;
        transform: translate3d(0, calc(var(--equinox-pos) * -33.33333%), 0);
        width: 100%;
        will-change: transform;
    }

    &__content {
        background-color: rgb(var(--color-bg));
        box-shadow: 0 24px 48px rgba(0, 0, 0, 0.15);
        margin-bottom: space(4xl, -1);
        padding: space(xl) space(l);
        position: relative;
        transform: translate3d(0, calc(3.5rem * var(--equinox)), 0);
        will-change: transform;
        z-index: 2;

        @include screen-min(l) {
            border-left: space(3xl) solid rgb(var(--color-bg));
            padding: space(4xl) space(3xl) space(4xl) 0;
            margin: 0 0 0 calc(50% - 4rem);
        }
    }

    &__heading {
        margin-bottom: space(xxl);
    }

    &__body {
        margin-bottom: space(xl);

        @include screen-min(l) {
            font-size: var(--font-size-l);
            margin-bottom: space(xxl);            
        }
    }

    &__buttons {
        display: flex;

        .c-button {
            @include screen-max(l) {
                flex: 0 0 100%;
            }
        }
    }
}