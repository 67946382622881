.o-media {
    width: 100%;
    height: 0;
    position: relative;

    iframe {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &--1\:1 {
        padding-top: 100%;
    }

    &--16\:9 {
        padding-top: 56.25%;
    }
}