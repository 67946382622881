@mixin margin($selector, $margin) {
    #{$selector} {
        margin-bottom: $margin !important;
    }
}

@include margin('.u-m-none', 0);

@each $key, $val in $spaces {
    @include margin('.u-m-#{$key}', $val);
}

@each $screen in map-keys($screens) {
    @include screen-min($screen) {
        @include margin('.#{$screen}\\:u-m-none', 0);

        @each $key, $val in $spaces {
            @include margin('.#{$screen}\\:u-m-#{$key}', $val);
        }
    }
}