.c-reviews {
    padding: 0 !important;
    position: relative;

    @include screen-min(l) {
        padding: 0 0 space(6xl) 0 !important;
    }

    .o-grid {
        @include screen-min(l) {
            flex-direction: row-reverse !important;
        }
    }

    &__photos {
        display: flex;
    }

    &__photo {
        flex: 0 0 50%;
    }

    &__photoMobile {
        margin: 0 -10px;

        @include screen-min(l) {
            margin: 0;
            position: sticky;
            top: space(s);
        }
    }

    &__photo {
        &--1,
        &--2 {
            @include screen-max(l) {
                display: none;
            }
        }

        &--2 {
            margin-bottom: space(s);
        }

        &--3 {
            @include screen-max(l) {
                height: 0;
                padding-bottom: 56.25%;
                overflow: hidden;
                position: relative;

                img {
                    height: 150%;
                    left: 0;
                    object-fit: cover;
                    position: absolute;
                    top: 0;
                    transform: translate3d(0, calc(var(--equinox-pos) * -33.33333%), 0);
                    width: 100%;
                    will-change: transform;
                }
            }
        }
    }


    &__content {
        padding: space(xxl) 10px;

        @include screen-min(l) {
            float: right;
            padding: space(6xl) space(3xl) 0 10px;
            width: 90%;
        }
    }

    &__listItem {
        &:not(:last-child) {
            &::after {
                background: rgb(var(--color));
                content: '';
                display: block;
                height: 3px;
                margin: space(l) auto space(xxl);
                width: 80px;
            }
        }
    }
}