:root {
    --border-radius: 4px;
    --duration-fast: 150ms;
    --duration-medium: 250ms;
    --duration-slow: 500ms;

    // Color
    --color: 187 85 68;                 // #BB5544 (187 85 68)
    --color-bg: 246 241 238;            // #FFFFFF
    --color-text: 187 85 68;            // #000000

    --color-button: 255, 255, 255;      // #FFFFFF
    --color-button-hover: 0, 255, 255;  // #00EEEE
    --color-button-text: 0, 0, 0;       // #000000

    --color-button-primary: 187 85 68;
    --color-button-primary-hover: 187 85 68;
    --color-button-primary-text: 255 255 255;
    --color-button-primary-text-hover: 255 255 255;

    --color-link: 187 85 68;          // #00DDDD
    --color-link-hover: 187 85 68;    // #00EEEE

    // Type
    --font-1: 'DM Sans', sans-serif;
    --font-1-spacing: 0;

    --font-2: 'PT Serif', sans-serif;
    --font-2-spacing: 0;

    --font-mono: 'DM Mono', monospace;
    --font-mono-spacing: -0.1em;

    --font-size-xxs: 11px;
    --font-size-xs: 12px;
    --font-size-s: 14px;
    --font-size-m: 16px;
    --font-size-l: 18px;
    --font-size-xl: 20px;
    --font-size-xxl: 28px;
    --font-size-3xl: 36px;

    --line-height-s: 1.3;
    --line-height-m: 1.5;
    --line-height-l: 1.75;
}

@media (min-width: 768px) {
    :root {
        --font-size-xxl: 36px;
    }
}

@media (min-width: 1024px) {
    :root {
        --font-size-l: 20px;
        --font-size-xl: 24px;
        --font-size-xxl: 48px;
    }
}

.t-inverted {
    --color-bg: 187 85 68;
    --color-text: 246 241 238;

    --color-link: 246 241 238;
    --color-link-hover: 246 241 238;

    --color-button-primary: 255 255 255;
    --color-button-primary-hover: 255 255 255;
    --color-button-primary-text: 187 85 68;
    --color-button-primary-text-hover: 0 0 0;
}

$grid-columns: 12 !default;

$screens: (
    xs: 320px,
     s: 375px,
     m: 768px,
     l: 1024px,
    xl: 1280px,
) !default;

$spaces: (
    xxs: 0.125rem, // 2px
     xs: 0.25rem,  // 4px
      s: 0.5rem,   // 8px
      m: 1rem,     // 16px
      l: 1.5rem,   // 24px
     xl: 2rem,     // 32px
    xxl: 3rem,     // 48px
    3xl: 4rem,     // 64px
    4xl: 5rem,     // 80px
    5xl: 6rem,     // 96px
    6xl: 7rem,     // 112px
) !default;

$wrapper-widths: (
    s: 640px,
    m: 800px,
    l: 1440px,
) !default;