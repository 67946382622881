.c-projects {
    &__bleed {
        @include screen-max(l) {
            margin: 0 -10px;
        }
    }

    &__list {
        column-count: 2;
        column-gap: space(s);
        page-break-inside: avoid;

        @include screen-min(m) {
            column-gap: space(l);
            column-count: 3;
        }

        @include screen-min(l) {
            column-count: 4;
        } 
    }

    &__listItem {
        break-inside: avoid-column;
        margin-bottom: space(s);
        position: relative;
    }
}