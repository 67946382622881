@keyframes tabPanel {
    0% { opacity: 0; transform: translateY(32px); }
    100% { opacity: 1; transform: translateY(0px); }
}

.c-tabs {
    &__tabList {
        display: flex;
        gap: space(l);
        margin-bottom: space(3xl);
    }

    &__tab {
        @include font-1;
        appearance: none;
        background: transparent;
        border: none;
        color: rgb(var(--color) / 50%);
        cursor: pointer;
        font-size: var(--font-size-3xl);
        margin: 0;
        padding: 0;
        position: relative;
        transition: var(--duration-medium) ease;

        &::after {
            background: rgb(var(--color));
            bottom: -2px;
            left: 0;
            position: absolute;
            content: '';
            height: 2px;
            transform: scaleX(0);
            transform-origin: 0 0;
            transition: var(--duration-medium) ease;
            width: 100%;
        }

        &:first-child {
            &::after {
                transform-origin: 100% 0;
            }
        }

        &:hover {
            color: rgba(var(--color), 1);
        }

        &[aria-selected='true'] {
            color: rgba(var(--color), 1);

            &::after {
                transform: scaleX(1);
            }
        }
    }

    &__tabPanel {
        animation: tabPanel var(--duration-slow) both ease;
        will-change: opacity, transform;
    }
}